<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row align-h="center">
        <b-col cols="4">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="No Customer Service" label-for="phone">
                  <b-form-input
                    id="reminder"
                    placeholder="No Telp"
                    v-model="form.customer"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2"
              @click="postData(2)"
            >
              Kirim
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="No Kontak Towing" label-for="phone">
                  <b-form-input
                    id="reminder"
                    placeholder="No Telp"
                    v-model="form.towing"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2"
              @click="postData(3)"
            >
              Kirim
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="No Kontak Asuransi" label-for="phone">
                  <b-form-input
                    id="reminder"
                    placeholder="No Telp"
                    v-model="form.insurance"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2"
              @click="postData(1)"
            >
              Kirim
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        insurance: "",
        customer: "",
        towing: "",
      },
      errors: "",
      errMessage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http
        .get("contacts")
        .then((response) => {
          console.log(response.data.data[0].phone)
          this.form.insurance = response.data.data[0].phone
          this.form.customer = response.data.data[1].phone
          this.form.towing = response.data.data[2].phone
          // this.form.customer = response.data[1].phone
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    postData($type) {
      var $phone = "";

      if ($type === 1){
        $phone = this.form.insurance;
      } else if ($type === 2){
        $phone = this.form.customer;
      } else {
        $phone = this.form.towing;
      }

      this.$http
        .post("contact/add", {
          phone: $phone,
          type: $type
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "No Telp berhasil diupdate",
              variant: "success",
            },
          });
          setTimeout(() => {
            this.$router.push("/settings/contacts");
          }, 2000);
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>